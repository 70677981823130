import type { ContextBabylonProps } from '@babylon/ui-kit-context/types';
import BaseTemplateLogitravel from '@babylon/ui-kit-layout/components/logitravel/base-template';
import type { FC } from 'react';

import type { MainHomeMobileProps, MainHomeProps } from '@/pages/circuits/home';
import MainHomeLanding from '@/pages/circuits/home-landing';
import { renderPages } from '@/utils/render';

import { transformProps } from '../home/utils';

export interface AppProps {
	root: MainHomeProps;
	context: ContextBabylonProps;
}
export interface AppPropsMobile {
	root: MainHomeMobileProps;
	context: ContextBabylonProps;
}

export const App: FC<AppProps> = props => {
	const { templateProps, mainHomeProps } = transformProps(props);
	return (
		<BaseTemplateLogitravel {...templateProps}>
			<MainHomeLanding {...mainHomeProps} />
		</BaseTemplateLogitravel>
	);
};

export const render = renderPages(App);
