import ClickToCall from '@babylon/ui-kit-common/components/others/click-to-call';
import ScrollToTop from '@babylon/ui-kit-common/components/others/scroll-to-top';
import type { FC } from 'react';

import { renderBreadcrumbs } from '@/pages/circuits/common/desktop';
import type { MainHomeProps } from '@/pages/circuits/common/types';
import { renderComponentsWithOrder } from '@/utils/render/components';

import {
	componentDesktopMapping,
	renderLinksSeo,
	renderSearcherCir,
	renderTextHtmlCollapse,
} from '../../../common/desktop';
import { dataConverter } from '../../../common/desktop/converter';

const DEFAULT_ORDER_DESKTOP = [
	'--DEFAULT_ORDER_DESKTOP--',
	'config',
	'cardGallery',
	'tabsCarousel',
	'tabsProductCir',
	'carouselOffers',
	'flipCards',
	'masonry',
	'mansory',
	'travelSafe',
	'features',
	'carouselCardSimple',
	'comarketingCarousel',
	'comarketing',
	'carouselListCir',
];

const MainHomeLandingDesktop: FC<MainHomeProps> = ({ props }) => {
	const { config = {} } = props;
	const { order } = config;
	const rendercomponentsOrder = order?.length ? order : DEFAULT_ORDER_DESKTOP;

	const { data } = dataConverter(props);
	return (
		<main data-testid='MainHomeLanding'>
			{data.headerBanner && componentDesktopMapping['headerBanner'](data.headerBanner)}
			{data.carousel && componentDesktopMapping['headerBanner'](data.carousel)}
			{renderSearcherCir(data.searcher, data.config)}
			{renderBreadcrumbs(data.breadcrumbs)}
			{renderComponentsWithOrder(rendercomponentsOrder, data, componentDesktopMapping)}
			{renderTextHtmlCollapse(data.textCutCollapseBox)}
			{renderLinksSeo(data.linksSeo)}
			<ScrollToTop />
			{data.clickToCall && <ClickToCall {...data.clickToCall} />}
		</main>
	);
};

export default MainHomeLandingDesktop;
